<template>
  <dashboard-layout v-slot="userAccess">
    <loader-animation v-if="isLoading" />
    <div
      v-if="userAccess.userAccess.access.isRevoke"
      class="text-red-400 font-bold w-full text-center mb-5"
    >
      <p>
        Seu acesso foi expirado em
        {{ userAccess.userAccess.access.revoke_access_at }}.
      </p>
      <p>
        Entre em contato com o seu sindicato para liberação do seu acesso
        definitivo.
      </p>
      <p>
        Sindicato: {{ userAccess.userAccess.informations.syndicate.name }}.
        Telefone:
        {{
          userAccess.userAccess.informations.syndicate.telephone
            | VMask('(##) ####-####')
        }}
      </p>
    </div>
    <modal-layout>
      <template v-slot:header><h1>Atenção</h1></template>
      <template v-slot:body
        ><p>
          Seu acesso foi expirado! Por favor, entre em contato com seu sindicato
          para solicitar renovação do seu acesso!
          <br /><br />
          Sindicato: {{ userAccess.userAccess.informations.syndicate.name }}.
          <br />
          Telefone:
          {{
            userAccess.userAccess.informations.syndicate.telephone
              | VMask('(##) ####-####')
          }}
        </p></template
      >
    </modal-layout>
    <div class="w-full h-full p-5" v-if="!isLoading">
      <div class="flex mb-4 justify-between">
        <router-link
          :to="{ name: 'search-results' }"
          class="bg-green-1000 p-2 text-white rounded-lg self-end cursor-pointer hover:bg-green-1001"
        >
          <svg
            style="display: initial;"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-chevron-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
            />
          </svg>
          Voltar
        </router-link>
        <button
          class="bg-green-1000 w-40 p-2 text-white rounded-lg self-end cursor-pointer hover:bg-green-1001"
          type="button"
          @click="newSearch"
        >
          Nova colheita
        </button>
      </div>
      <div class="flex flex-col">
        <div class="flex items-center p-2">
          <p class="text-lg">{{ participants.length }} talentos colhidos</p>
        </div>
        <div class="flex bg-white w-full rounded px-5 py-2 mb-2">
          <div class="w-3/12 px-1">Nome</div>
          <div class="w-2/12 px-1">Cidade</div>
          <div class="w-2/12 px-1">Escolaridade</div>
          <div class="w-2/12 px-1">E-mail</div>
          <div class="w-2/12 px-1">Celular</div>
          <div class="w-1/12 text-center">Currículo</div>
        </div>
        <div
          v-for="participant in participants"
          :key="participant.id"
          class="flex items-center bg-white w-full rounded px-5 py-2 mb-2 hover:bg-gray-400"
        >
          <div class="w-3/12 px-1">{{ participant.name | capitalize }}</div>
          <div class="w-2/12 px-1">{{ participant.city }}</div>
          <div class="w-2/12 px-1">{{ participant.education_level }}</div>
          <div class="w-2/12 px-1 break-all"
          v-tooltip="{
                content:
                  'Para visualizar o E-mail do talento acesse o curriculo através do botão Visualizar.',
                placement: 'top'
              }">
            {{ participant.email | filterEmail }}
          </div>
          <the-mask
            class="w-2/12 px-1 bg-transparent focus:outline-none"
            mask="(##) # ####-####"
            v-tooltip="{
                content:
                  'Para visualizar o Telefone do talento acesse o curriculo através do botão Visualizar.',
                placement: 'top'
              }"
            :value="participant.cellphone"
            :tokens="anonymousTokens"
          ></the-mask>
          <div class="w-1/12 flex justify-center items-center">
            <button
              class="mr-3 text-sm bg-yellow-500 hover:bg-yellow-700 black py-1 px-2 rounded focus:outline-none focus:shadow-outline"
              @click="
                viewCurriculum(
                  userAccess.userAccess.access.isRevoke,
                  participant.id
                )
              "
            >
              Visualizar
            </button>
          </div>
        </div>
      </div>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '../../layouts/DashboardLayout';
import store from '@/store';
import LoaderAnimation from '@/components/LoaderAnimation';
import { TheMask } from 'vue-the-mask';
import axios from '@/utils/axios';
import ModalLayout from '../../components/Modal';

export default {
  name: 'SearchOtherResults',

  title() {
    return `${process.env.VUE_APP_NAME} | Outros Talentos`;
  },

  data() {
    return {
      participants: [],
      isLoading: false,
      anonymousTokens: {
        '#': { pattern: /[\d*]/ }
      }
    };
  },
  components: {
    DashboardLayout,
    LoaderAnimation,
    TheMask,
    ModalLayout
  },
  methods: {
    getParticipants: async function() {
      this.isLoading = true;

      await axios
        .post('/api/busca/outros-registros', {
          search: store.state.search,
          cities: store.state.cities.map(city => city.id),
          // course: store.state.course,
          Experience: store.state.Experience,
          education_level: store.state.education_level,
          gender: store.state.gender,
          min_age: store.state.min_age,
          max_age: store.state.max_age,
          participant_name: store.state.participant_name
        })
        .then(response => {
          this.participants = response.data;

          this.isLoading = false;
        });
    },
    viewCurriculum(isRevoke, id) {
      if (isRevoke) {
        this.$modal.show('modal');
      } else {
        axios.patch('/api/participantes/salvar-visualizacao', {
        participant_id: id
      });
        let route = this.$router.resolve({
          name: 'curriculum',
          params: { id: id }
        });
        window.open(route.href, '_blank');
      }
    },

    newSearch() {
      store.commit('setSearch', '');
      // store.commit('setCourse', '');
      store.commit('setExperience', '');
      store.commit('setEducationLevel', '');
      store.commit('setGender', '');
      store.commit('setMinAge', '');
      store.commit('setMaxAge', '');
      store.commit('setCities', '');
      store.commit('setParticipantName', '');
      store.commit('setYear', '');
      store.commit('setComplementaryCurriculum', '');

      localStorage.removeItem('searchParams');
      localStorage.removeItem('selectedParticipants');

      this.$router.push({
        name: 'search'
      });
    }
  },
  filters: {
    filterEmail(email) {
      return email.search('@teste.com.br') > 0 ? '' : email.toLowerCase();
    },
    capitalize(name) {
      if (!name) return '';

      name = name.toString();

      name = name.split(' ');

      name = name
        .map(part => {
          return part.charAt(0).toUpperCase() + part.toLowerCase().slice(1);
        })
        .map(part => {
          return ['e', 'da', 'do', 'de', 'das', 'dos'].indexOf(
            part.toLowerCase()
          ) !== -1
            ? part.toLowerCase()
            : part;
        });

      return name.join(' ');
    }
  },
  created: function() {
    this.getParticipants();
  }
};
</script>

<style scoped></style>
